<template>
  <div>
    <el-popover
      placement="top"
      width="400"
      trigger="hover"
    >
      <span
        slot="reference"
      >
        {{ truncatedText }}
        </span>
      <div v-html="text"></div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "collapse-text-with-tooltip",
  props: {
    text: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      default: 20
    }
  },
  computed: {
    truncatedText() {
      if (this.text.length > this.maxLength) {
        return this.text.slice(0, this.maxLength) + "...";
      }
      return this.text;
    }
  }
};
</script>

<style scoped>

</style>